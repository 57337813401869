import * as React from "react";
import { SEO } from "../components/seo";
import { Layout } from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./about.css";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      bioImage: file(
        relativePath: { eq: "andrea-bio.jpeg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const bioImage = getImage(data.bioImage);

  return (
    <Layout>
      <div className={"about-wrapper"}>
        <div className={"about-content-wrapper"}>
          <div className={"about-image-wrapper"}>
            <div className={"about-image-background"}>
              <GatsbyImage
                image={bioImage}
                alt={"test alt"}
                imgClassName={"about-image"}
              />
            </div>
            <h1 className={"about-image-text"}>
              All
              <br />
              About
              <br />
              Andrea
            </h1>
          </div>
          <p className={"about-content"}>
            <h1 className={"about-header-text"}>Biography</h1>
            <span style={{ color: "#FF5A19" }}>Andrea Lopez</span> is a Puerto
            Rican actress who has appeared on stages in New York City, London,
            and Puerto Rico, playing a wide assortment of roles from classical
            to surrealist and all the fun things in between.
            <br />
            <br />
            Recently, she played Remedios Varo in Exquisite Corpse Company’s A
            Ribbon About a Bomb and Echo in Arctic Group’s Echo as well as
            starring in an assortment of films and TV projects, including the
            upcoming series Posers.
            <br />
            <br />
            Classically trained at Columbia University and in London’s BADA,
            Andrea is well-versed in all styles from Shakespearean to modern
            drama. She has also taken voice, movement, and stage combat classes
            and is fluent in both Spanish and English.
            <br />
            <br />
            Andrea just wrapped filming on The Surrealists, where she got to
            play Leonora Carrington, an immensely talented, yet constantly
            overlooked artist who lives in the shadow of Frida Kahlo. She can
            next be seen as Ariel, a modern interpretation of the Disney
            Princess, in Princess Anybody, a reading of Ran Xia’s newest work
            being presented as part of Torn Out Theatre’s Ripped Up series. She
            is also set to star as Bea in the feature film LU2, a coming of age
            romantic comedy set in the crazy world of all-girls education.
            <br />
            <br />A Puerto Rico native, Andrea currently resides in New York
            City.
            <h1 className={"about-header-text"}>Resume</h1>
            <div className={"about-resume-wrapper"}>
              <iframe
                className={"about-resume-iframe"}
                title={"Andrea Lopez Resume"}
                src={
                  "https://drive.google.com/file/d/1hyuSehFA9vR2I0VjN-YzN9zrcPShVqti/preview"
                }
                allowFullScreen={true}
                frameBorder={"0"}
              />
            </div>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default About;

export const Head = () => <SEO title={"About"} />;
